import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "menu",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
    data: {
      containerEnabled: true
    }
  },
];
